.leftHalf {
  width: 50%;
  position: absolute;
  right: 0px;
  height: 50%;
  top: 100px;
  bottom: 0px;
}

#rightHalf {
  width: 50%;
  position: absolute;
  right: 0px;
  height: 100%;
}

.verticalAdjust{
 position: relative;
 top: 200px;
}
/* 
.jamesBack {
  background: url(../assets/transparentJames.png);
  width: 100%;
  background-size: cover;
  background-position: right;
  color: #efefef;
  height: 100%;
  position: relative;
} */