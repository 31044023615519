.borders {
  border-bottom: solid red 1px;
}

.navLinks {
  text-decoration: none;
  color: white;
}

.navLinks:link {
  text-decoration: none;
}

.navLinks:visited {
  text-decoration: none;
}

.navLinks:hover {
  text-decoration: none;
  color: rgb(106, 106, 106);
  transition: all .5 ease-in-out;
}