.blogPhoto {
  width: 100%;
}

.center {
  text-align: center;
}

.blogBack {
  background-color: rgb(26, 26, 26);
  position: relative;
  z-index: 0;
}

/* rgb(26, 26, 26) */

.card {
  background-color: rgb(36, 36, 36);
}

.cardText {
  color: rgb(192, 192, 192);
}

.buttonColor {
  background-color: rgb(174, 33, 33);
  border-color: rgb(174, 33, 33);

}

.buttonColor:hover {
  background-color: rgb(170, 15, 15);
  border-color: rgb(170, 15, 15);
}


.linkBack:hover {
  color: rgba(255, 255, 255, 0.412);
}