.jumboContact {
  background: url(../../assets/hello3.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  color: #efefef;
  height: 400px;
  position: relative;
  z-index: 3;
}

.overlay {
  background-color: #000;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 4;
}

.h1White {
  color: #c4bebe;
}

.align {
  align-items: center;
}