.greyBack {
  background-color: #555555;
  z-index: -6;
}

.whiteBack {
  background-color: #555555;
}

.aboutPhoto {
  width: 75%;
}

.aboutPhoto2 {
  position: relative;
  width: 60%;
  z-index: 1;
}

.fitPage {
  margin-left: 30px;
  margin-right: 30px;
}

.linksAbout {
  text-decoration: none;
  color: rgb(0, 0, 0);
}

.linksAbout:link {
  text-decoration: none;
}

.linksAbout:visited {
  text-decoration: none;
}

.linksAbout:hover {
  text-decoration: none;
  color: rgb(48, 48, 48);
  transition: 1;
}

.red {
  color: #ff5757;
}

.white {
  color: #ffffff;
}

.black {
  color: black;
}

.burgundy {
  color: rgb(82, 0, 0);
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.rightPhoto {
  align-items: flex-end;
}

.front {
  position: relative;
  z-index: 5;
}

.circle1 {
  z-index: 0;
  position: absolute;
  right: 20px;
  width: 20%;
}
/* 
.circle1 {
  z-index: 0;
  position: absolute;
  right: -800px;
} */

.circle1Width {
  width: 20%;
}

.circle2 {
  z-index: 0;
  position: absolute;
  right: 70%;
  width: 400px;
  top: 85px;
}

/* .circle3 {
  z-index: 0;
  position: absolute;
  right: 35%;
  width: 200px;
} */

.circle3 {
  z-index: 0;
  position: absolute;
  right: 35%;
}

.circle3Width {
  width: 200px;
}

.circle4 {
  z-index: -5;
  position: absolute;
  right: 45%;
  width: 600px;
}

.circle4 {
  z-index: -5;
  position: absolute;
  right: 45%;
  width: 600px;
}

.circle5 {
  z-index: 0;
  position: absolute;
  right: 20px;
  width: 75%;
}

.circle6 {
  position: absolute;
  left: -10%;
  width: 300px;
}

.circle7 {
  position: absolute;
  right: 80%;
  width: 200px;
}

.circle8 {
  position: absolute;
  left: -45%;
  width: 400px;
}

.circle9 {
  position: absolute;
  right: 5%;
  width: 240px;
  z-index: 0;
}

.back {
  z-index: -5;
}

.fontSize {
  font-size: 30px;
}