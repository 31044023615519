.baggageBack {
  background-color: rgb(26, 26, 26);
  position: relative;
  z-index: 0;
}

.obj {
  color: rgb(138, 138, 138);
}

.devotional {
  width: 100%;
  /* height: 400px; */
}

.devText {
  font-size: x-large;
  color: rgb(192, 192, 192);
}

.devBanContainer {
  position: relative;
  text-align: center;
  color: white;
}

/* Centered text */

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.exercise {
  background: url(../../assets/exercise.jpg) no-repeat center;
  background-size: cover;
  background-position: center;
  color: #efefef;
  height: 400px;
  position: relative;
  z-index: -2;
}

.exerciseOverlay {
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.devotional {
  background: url(../../assets/devotional.jpeg) no-repeat center;
  background-size: cover;
  background-position: center;
  color: #efefef;
  height: 400px;
  position: relative;
  z-index: -2;
}

.devotionalOverlay {
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}