.jumboBag {
  background: url(../../assets/BaggageJumbo.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  color: #efefef;
  height: 400px;
  position: relative;
  z-index: -2;
}


.align {
  align-items: center;
}