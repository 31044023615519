.contactBackground {
  background-color: rgb(26, 26, 26);
}

.contactPhoto {
  width: 100%;
}

.font {
  /* font-family: "Cursive"; */
  color: white;
  font-size: x-large;
}

.circleContact1 {
  z-index: 0;
  position: absolute;
  left: 40%;
}

.contactCirclewidth {
  width: 200px;
}